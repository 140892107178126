import React from "react";
import Link from "../misc/link";

const IntegrationIntegrationFeatureSection = ({ heading, subHeading, integration1, integration2 }) => {
    const IntegrationInformationBox = ({ integration }) => {
        return (
            <div className="sample">
                <ul className="st-logo-boxes integrations-header-logos">
                    <li className="logo-box">
                        <Link to={`/integrations/${integration.url}/`} className={`logo-box-border no-hover`}>
                            <div className="logo-info">
                                <img src={integration.cropped_logo.file.url} alt={integration.name} />
                            </div>
                        </Link>
                    </li>
                </ul>
                {integration.integration_category.name === "Databases" ? (
                    <p className={`st-subheading`}>
                        <strong>{integration.name}</strong> is a popular database tool.
                    </p>
                ) : (
                    <p className={`st-subheading`}>
                        <strong>{integration.name}</strong> {integration.short_description}
                    </p>
                )}
                {integration.documentation_link ? (
                    <>
                        <p>Stitch offers detailed documentation on how to sync your {integration.name} data.</p>
                        <p>
                            <Link
                                to={`https://www.stitchdata.com/docs${integration.documentation_link}`}
                                className={`st-button-plain--alt`}
                            >
                                Stitch {integration.name} Documentation<span className="arrow"></span>
                            </Link>
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            The Stitch {integration.name} integration is maintained by the open source Singer community.
                        </p>
                        <p>
                            <Link to={integration.gitHubRepository} className={`st-button-plain--alt`}>
                                View the Repo<span className="arrow"></span>
                            </Link>
                        </p>
                    </>
                )}
            </div>
        );
    };

    return (
        <section className="st-section">
            <div className="st-feature--thin about-feature">
                <h2 className="st-heading--2">{heading}</h2>
                <p className="st-subheading">{subHeading}</p>
                <IntegrationInformationBox integration={integration1} />
                <IntegrationInformationBox integration={integration2} />
            </div>
        </section>
    );
};

export default IntegrationIntegrationFeatureSection;
