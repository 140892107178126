import React from "react";
import Link from "../misc/link";
import CodeBlock from "../misc/code-block";

const AnalyticsBlocks = ({ integration }) => {
    const allBlocks = integration.analytics_block;

    let integrationHasDbtBlock = false;
    let intergrationShowAnalyticsBlocks = false;
    let integrationHasLookerBlock = false;
    let dbtBlock = false;
    let lookerBlock = false;

    if (allBlocks && allBlocks.length > 0) {
        intergrationShowAnalyticsBlocks = true;

        if (allBlocks.some((block) => block.analytics_package.some((block) => block === "dbt"))) {
            integrationHasDbtBlock = true;
            dbtBlock = allBlocks.filter((block) => block.analytics_package.some((b) => b === "dbt"))[0];
        }

        if (allBlocks.some((block) => block.analytics_package.some((blockPackage) => blockPackage === "Looker"))) {
            integrationHasLookerBlock = true;
            lookerBlock = allBlocks.filter((block) =>
                block.analytics_package.some((blockPackage) => blockPackage === "Looker")
            )[0];
        }
    }

    const DBTBlock = ({ block }) => {
        return (
            <section className="st-section">
                <div className="st-callout">
                    <div className="callout-content">
                        <h2 className="st-heading--2">
                            Jumpstart your {integration.name} analytics with reusable blocks
                        </h2>
                        <h3 className="st-heading--3"> {block.analytics_package[0]} packages can speed up your work</h3>
                        <p>
                            Once you replicate your {integration.name} data with Stitch, you can use it in many ways.
                            For example, you can use the data modeling and transformation tool dbt to prepare data for
                            reporting, analytics, or machine learning applications.
                        </p>
                        <p>
                            Dbt has prebuilt packages for many Stitch data sources, including {integration.name}. Here’s
                            a look at code for modeling {integration.name} data. {block.description.description}
                        </p>
                        {block.block_author && block.block_author !== "Fishtown Analytics" && (
                            <p>This block was created by {block.block_author}.</p>
                        )}
                        {block.link_to_source && (
                            <Link className="st-button-outline" to={block.link_to_source}>
                                View the source on GitHub &rarr;
                            </Link>
                        )}
                    </div>
                    <div className="callout-decor">
                        <div className="dbt-blocks-code">
                            <CodeBlock id={`code-snippet`} className={`language-markup`}>
                                {block.code_snippet.code_snippet}
                            </CodeBlock>
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    const LookerBlock = ({ block }) => {
        return (
            <section className="st-section">
                <div className="st-callout">
                    <div className="callout-content">
                        {!integrationHasDbtBlock ? (
                            <>
                                <h2 className="st-heading--2">
                                    Jumpstart your {integration.name} analytics with reusable blocks
                                </h2>
                                <h3 className="st-heading--3">
                                    {" "}
                                    {block.analytics_package[0]} packages can speed up your work
                                </h3>
                            </>
                        ) : (
                            <h3 className="st-heading--3">
                                ... or use {block.analytics_package[0]} blocks with your {integration.name} data
                            </h3>
                        )}
                        {!integrationHasDbtBlock && (
                            <p>
                                Since Stitch replicates data to a consistent schema, it works well with other tools in
                                your stack. Once you have BLOCKNAME HERE data in your data warehouse you can use it in
                                many ways. Modeling tools such as dbt and Looker Blocks can help you prepare your data
                                for reporting, analytics, or machine learning.
                            </p>
                        )}
                        <p>
                            {block.block_author && !["Looker", "Stitch"].includes(block.block_author) && (
                                <>{block.block_author} has developed a Looker Block </>
                            )}
                            {block.block_author && block.block_author === "Looker" && <>Looker developed a block </>}
                            {block.block_author && block.block_author === "Stitch" && (
                                <>We've developed a Looker Block </>
                            )}
                            for {integration.name} data provisioned by Stitch. This block includes prebuilt code to
                            create dashboards and models that can help uncover insights from your {integration.name}{" "}
                            data.
                        </p>
                        <p>{block.description.description}</p>
                        {block.link_to_source && (
                            <Link className="st-button-outline" to={block.link_to_source}>
                                View the source on GitHub &rarr;
                            </Link>
                        )}
                    </div>
                    <div className="callout-decor">
                        <div className="dbt-blocks-code">
                            <CodeBlock id={`code-snippet`} className={`language-markup`}>
                                {block.code_snippet.code_snippet.trim()}
                            </CodeBlock>
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    return (
        <>
            {intergrationShowAnalyticsBlocks ? (
                <div id="analytics-blocks" className="st-crop">
                    {integrationHasDbtBlock && <DBTBlock block={dbtBlock} />}
                    {integrationHasLookerBlock && <LookerBlock block={lookerBlock} />}
                </div>
            ) : null}
        </>
    );
};

export default AnalyticsBlocks;
